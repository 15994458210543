export class CSV {
    static parseCSV(csvString, customHeaders = []) {
        const lines = csvString.trim().split('\n');
        const detectedHeaders = lines[0].split(',');
        const useDetectedHeaders = detectedHeaders.every(header => isNaN(header));
        
        const headers = !useDetectedHeaders && customHeaders.length
            ? customHeaders.slice(0, detectedHeaders?.length)
            : detectedHeaders;

        const dataLines = useDetectedHeaders ? lines.slice(1) : lines;

        return dataLines.map(line => {
            const values = line.split(',');
            return headers.reduce((row, header, idx) => {
                row[header] = values[idx] || '';
                return row;
            }, {});
        });
    }



    static parseCSVFile(file, headers) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const csvString = event.target.result;
                    const parsedData = this.parseCSV(csvString, headers);
                    resolve(parsedData);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = reject;
            reader.readAsText(file);
        });
    }

    static downloadCSV(csvString, filename) {
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    static convertToCSV(data) {
        if (!data.length) return '';
        const headers = Object.keys(data[0]);
        const csvRows = [
            headers.join(','),
            ...data.map(row => headers.map(header => row[header] || '').join(','))
        ];
        return csvRows.join('\n');
    }
}
