import { addMinutes, differenceInMinutes, format } from "date-fns";
import { useEffect, useState } from "react";
import { LineChartCustom } from "./widgets/LineChartCustom";


const timescale = [
	'10:00',
	'10:30',
	'11:00',
	'11:30',
	'12:00',
	'12:30',
	'13:00',
	'13:30',
	'14:00',
	'14:30',
	'15:00',
	'15:30',
	'16:00',
	'16:30',
	'17:00',
	'17:30',
	'18:00',
	'18:30',
	'19:00',
	'19:30',
	'20:00',
	'20:30',
	'21:00',
	'21:30',
	'22:00',
	'22:30',
	'23:00',
	'23:30',
	'00:00',
	'00:30',
	'1:00',
	'1:30',
	'2:00',
	'2:30',
	'3:00',
	'3:30',
	'4:00',
	'4:30',
	'5:00',
	'5:30',
	'6:00',
	'6:30',
	'7:00',
	'7:30',
	'8:00',
	'8:30',
	'9:00',
	'9:30',
	'10:00',
]

function getEquidistantDatesIncludingEnds(startDateString, endDateString, numberOfDates) {
	// const startDate = startOfDay(new Date(startDateString));
	// const endDate = endOfDay(new Date(endDateString));

	const durationInMinutes = differenceInMinutes(endDateString, startDateString);
	const intervalInMinutes = (durationInMinutes / (numberOfDates - 1));

	const dates = [];
	let currentDate = startDateString;
	for (let i = 0; i < numberOfDates; i++) {
		dates.push(format(currentDate, 'dd.MM HH:mm'));
		currentDate = addMinutes(currentDate, intervalInMinutes);
	}

	return dates;
}


export const WidgetCard = ({ data, startDate, endDate, selectedSliderDate, setSelectedSliderDate, sliderVal, setSliderVal, scaleWidth }) => {
	const MIN = scaleWidth

	useEffect(() => {
		const max = document.querySelector('.drag-parent').offsetWidth - MIN;
		const elmnt = document.getElementById('red-line');
		elmnt.style.left = (max * sliderVal / 100) + MIN + 'px'
	}, [sliderVal])

	const onMouseUp = () => {
		const max = document.querySelector('.drag-parent').offsetWidth - MIN;
		const pos = parseInt(document.getElementById('red-line').style.left, 10) - MIN;
		setSliderVal((pos * 100) / max);
	};
	function dragMouseDown(e) {
		e = e || window.event;
		e.preventDefault();
		document.onmouseup = closeDragElement;
		document.onmousemove = (event) => elementDrag(event, e.target);
	}
	function closeDragElement() {
		document.onmouseup = null;
		document.onmousemove = null;
		onMouseUp()
	}
	function elementDrag(e, elmnt) {
		e = e || window.event;
		e.preventDefault();
		const parent = elmnt.closest('.drag-parent');
		const parentOffset = parent.offsetLeft;
		const parentWidth = parent.offsetWidth;
		let pos = (e.clientX - parentOffset) >= MIN ? (e.clientX - parentOffset) : MIN;
		pos = pos <= (parentWidth) ? pos : (parentWidth);
		elmnt.style.left = pos + "px";
	}


	return (
		<>
			<div id="data-explorer-chart" className="widget-card rounded border border-solid shadow-sm bg-[#FBFBFB] border-red-600 border-opacity-30  dark:bg-[#1B1B1B] dark:text-white p-[16px] w-full overflow-hidden">
				<div className="drag-parent widget-inner relative flex flex-col">

					<div className="chart-group" style={{ maxHeight: 'unset' }}>
						{data.map((item, index) => (
							<LineChartCustom data={item} title={item.title} key={index} setSliderVal={setSliderVal} />
						))}
						<div className="red-line" id="red-line" style={{ left: MIN }} onMouseDown={dragMouseDown} onMouseUp={onMouseUp}>
							<div className="border-l-[4px] border-dashed border-[#E42320] h-full" style={{ pointerEvents: 'none' }}></div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

