import React, { useRef, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const SpeedometerChart = ({ needleValue, title, value }) => {
  const chartRef = useRef();

  const pieData = {
    labels: ['Speed'],
    datasets: [
      {
        data: [100], // One section that fills the semi-circle
        backgroundColor: ['#36A2EB'], // Set the gauge color
        hoverBackgroundColor: ['#36A2EB'],
      },
    ],
  };

  const options = {
    rotation: -90, // Start from the left side
    circumference: 180, // Only show a semi-circle
    cutout: '70%', // Cut out the center to make it look like a speedometer
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      onComplete: () => drawNeedle(),
    },
  };

  const drawNeedle = () => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart.ctx;
      const { width, height } = chart.chartArea;

      // Calculate needle angle
      const needleAngle = Math.PI + (Math.PI * needleValue) / 100;

      // Adjust the center coordinates to match the center of the cutout
      const centerX = width / 2;
      const centerY = height;

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(needleAngle);
      ctx.beginPath();
      ctx.moveTo(0, -5); // Start at the center
      ctx.lineTo(height / 2 - 10, 0); // Length of the needle
      ctx.lineTo(0, 5);
      ctx.fillStyle = 'rgba(0, 0, 0, 0.7)'; // Needle color
      ctx.fill();
      ctx.restore();
    }
  };

  return (
      <div className="widget-inner">
        <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
            {title}
          </div>
          <div className="w-full mt-1">
              <Pie ref={chartRef} data={pieData} options={options} />
      </div>
      <p className='text-center mt-2 text-blue-500 font-semibold text-xl'>{value}</p>
    </div>
  );
};

export default SpeedometerChart;
