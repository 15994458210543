import { useEffect } from "react"
import { ReactComponent as AnchorIcon } from "../../assets/img/MapAnchorwithNumberWhite.svg"
import { ReactComponent as PinIcon } from "../../assets/img/MapPinwithNumberWhite.svg"

export const PinWithNumber = ({ number, isHistorical, type, isCurrent = false, onRendered = () => { } }) => {
    useEffect(() => {
        onRendered()
    }, [onRendered])

    return (
        <div className={`relative size-[48px] ${isCurrent ? 'text-yellow-700' : isHistorical ? "text-[#343C44]" : "text-[#3477B5]"}`}>
            {type === "station_keeping" ? <AnchorIcon /> : <PinIcon />}
            <span className={`absolute top-[3px] left-1/2 -translate-x-1/2 text-[16px] font-semibold ${isCurrent ? 'text-yellow-700' : isHistorical ? "text-[#343C44]" : "text-[#3477B5]"}`}>
                {number}
            </span>
        </div>
    )
}
