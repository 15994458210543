import { Line } from "react-chartjs-2";
import React, { useId, useRef, useState } from "react";
import zoomPlugin from 'chartjs-plugin-zoom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { format } from "date-fns";

ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const downsample = (data, factor) => data.filter((_, index, arr) => {
    if (index === arr.length - 1) {
        return true
    }
    return index % factor === 0
});


const minMaxByInterval = (data, interval) => {
    const minMaxData = [];
    for (let i = 0; i < data.length; i += interval) {
        const segment = data.slice(i, i + interval);
        const min = Math.min(...segment);
        const max = Math.max(...segment);
        minMaxData.push({ min, max });
    }
    return minMaxData;
};


const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '10px';

            li.onclick = () => {
                const { type } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.flexShrink = 0;
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '20px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};

export const LineChartCustom = ({ data, title, setSliderVal }) => {
    const chartRef = useRef(null);
    const id = useId()

    const optimize = (data) => {
        const zoomLevel = data?.length || 0
        if (zoomLevel < 1000) {
            return data
        } else {
            return downsample(data, 10 * Math.floor(zoomLevel / 1000))
        }
    };


    const dataSourceColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
    const labels = optimize(data.data.labels)
    const chartData = {
        title: data.title,
        labels: labels,
        datasets: data.data.datasets.map((dataset, index) => ({
            ...dataset,
            data: optimize(dataset.data),
            backgroundColor: dataSourceColors[index % dataSourceColors.length],
            borderColor: dataSourceColors[index % dataSourceColors.length],
            pointBackgroundColor: dataSourceColors[index % dataSourceColors.length],
            pointRadius: 3,
            borderWidth: 1,
            tension: 0,
        })),
    };

    const options = {
        ...data.options,
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, elements) => {
            const max = event.chart.chartArea.right
            setSliderVal(event.x * 100 / max)
            // if (elements.length > 0) {
            //     const datasetIndex = elements[0].datasetIndex;
            //     const index = elements[0].index;
            //     const value = data.datasets[datasetIndex].data[index];
            //     alert(`Clicked point: ${value}`);
            // }
        },
        scales: {
            ...data.options.scales,
            x: {
                ...data.options.scales.x,
                ticks: {
                    ...data.options.scales.ticks,
                    align: "inner",
                    callback: function (index) {
                        const date = labels[index];
                        if (index === 0 || index === labels.length - 1
                            || index % (Math.ceil(labels.length / 5)) === 0
                        ) {
                            return format(new Date(date), 'd.MM H:mm');
                        } else {
                            return null
                        }
                    },
                }
            },
        },
        plugins: {
            htmlLegend: {
                containerID: `legend-container-${id}`,
            },
            legend: {
                display: false,
            },
            // legend: {
            //     display: true,
            //     position: "right",
            //     align: "start",
            //     labels: {
            //         boxWidth: 12,
            //         boxHeight: 12,
            //         padding: 20,
            //     },
            // },

            // title: {
            //     display: true, // Set to true to show the title
            //     position: "top",
            //     text: 'Title', // Set the title text
            //     font: {
            //         size: 16, // Set title font size
            //     },
            //     padding: {
            //         top: 10, // Optional: padding above the title
            //         bottom: 20 // Optional: padding below the title
            //     },
            //     align: 'start' // Align title (start, center, end)
            // },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'y',
                },

                pan: {
                    enabled: true,
                    mode: 'y',
                },
            },
        },
    };

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return (
        <>
            <h4 className="font-medium text-center">{title}</h4>
            <div className="flex flex-col w-full mb-[24px] relative overflow-hidden">
                <Line ref={chartRef} data={chartData} plugins={[htmlLegendPlugin]} options={options} />
                <div className="legend-container" id={`legend-container-${id}`} >
                </div>
                <button onClick={handleResetZoom}
                    // style={{ position: 'absolute', right: 0, bottom: 0 }}
                    className="ml-auto flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-max">
                    Reset zoom
                </button>
            </div>
        </>
    );
};
