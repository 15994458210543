import {useClickOutside} from "../helpers/useClickOutside";
import React, {useRef} from "react";
import rotateImg from "./../assets/img/rotateD.png"

const ConfigHeader = ({onClose}) => (
    <div
        className="flex gap-5 justify-between px-4 py-2 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-70 dark:text-white dark:bg-[#151515]">
        <div className="text-base font-semibold">Attention Required</div>
        <button onClick={onClose} aria-label="Close">
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-4 aspect-square close-icon"
                alt="Close icon"
            />
        </button>
    </div>
);

export const LandScapeModal = ({onClose}) => {
    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => onClose());

    return (
        <div
            ref={dropdownRef}
            style={{
                position: 'fixed',
                width: '80%',
                maxWidth: 400,
                top: '50%',
                left: '50vw',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000,
            }}
            className="flex flex-col text-sm rounded-lg shadow-lg bg-white dark:bg-[#1b1b1b] border border-solid border-neutral-300 dark:border-[#ffffff1a]"
        >
            <ConfigHeader onClose={onClose}/>

            <div className="flex flex-col p-4 w-full text-neutral-800 dark:text-neutral-300">
                <div className="flex flex-col items-center text-center">
                    <h2 className="text-lg font-semibold mb-2">
                        Switch to Landscape Mode
                    </h2>
                    <p className="text-sm mb-4">
                        This application is best experienced in landscape mode.
                        Please rotate your device to continue.
                    </p>
                    <div className="mb-4">
                        <img
                            src={rotateImg}
                            alt="Rotate device"
                            className="w-16 h-16"
                        />
                    </div>
                    <p className="text-sm">
                        If you are unable to rotate your device, please try accessing this page on a larger screen.
                    </p>
                </div>
                <button
                    onClick={onClose}
                    className="self-end mt-6 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
};
