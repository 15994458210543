export const convertLatLngToApiFormat = (obj) => {
    const convertedObj = {...obj}
    convertedObj.lng = obj.lon || obj.lng
    return convertedObj
}

export const isCurrentPoint = (latlng1, latlng2) => {
    const {lat: lat1, lng: lng1} = latlng1
    const {lat: lat2, lng: lng2} = latlng2
    const tolerance = 1e-4;

    return Math.abs(+lat1 - +lat2) < tolerance && Math.abs(+lng1 - +lng2) < tolerance
}

export const isCurrentPointWithTolerance = (latlng1, latlng2, tolerance = 10) => {

    const toRadians = (degrees) => (degrees * Math.PI) / 180;

    const {lat: lat1, lng: lng1} = latlng1;
    const {lat: lat2, lng: lng2} = latlng2;

    const R = 6371000;
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lng2 - lng1);

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance <= tolerance * 100;
};
