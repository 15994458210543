import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { NavLink, useNavigate } from "react-router-dom";


import AddWidgetIcon from "../../assets/img/icons/add-widget.svg";
import EditLayoutIcon from "../../assets/img/icons/edit-layout.svg";
import LoadIcon from "../../assets/img/icons/load.svg";
import ManageConfigsIcon from "../../assets/img/icons/manage-configs.svg";
import NewConfigIcon from "../../assets/img/icons/new-config.svg";
import SaveIcon from "../../assets/img/icons/save.svg";

import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { useClickOutside } from "../../helpers/useClickOutside";
import AddWidgetModal from "../../Modals/AddWidgetModal";
import LoadConfigModal from "../../Modals/LoadConfigModal";
import NewConfigurationModal from "../../Modals/NewConfigurationModal";
import SelectGraphData from "../../Modals/SelectGraphDataModal";
import { getUser } from "../../store/slices/applicationSlice";
import { getCalendarData } from "../../store/slices/filtersSlice";
import { getSocket } from "../../store/slices/shipsSocket";
import { fetchLayoutsData, fetchWidgetsData } from "../../store/thunks/dashboardThunks";
import { fetchFilterCalendar } from "../../store/thunks/filtersThunks";
import Widget from "./Widget";

const Modals = {
    addWidget: AddWidgetModal,
    loadConfig: LoadConfigModal,
    selectGraph: SelectGraphData,
    newConfig: NewConfigurationModal
}

const ActionButton = ({imgSrc, altText, actionText, transparent, path, clickAction}) => (
    path &&
    <NavLink to={path}
             className={`cursor-pointer flex gap-1 justify-between p-1 rounded border border-solid border-[color:#ae1b1d] ${transparent ? 'text-red-600' : 'bg-red-600'}`}>
        <img loading="lazy" src={imgSrc} alt={altText} className="w-6 aspect-square"/>
        <div className="grow my-auto">{actionText}</div>
    </NavLink> ||
    <button
        className={`cursor-pointer flex gap-1 justify-between p-1 rounded border border-solid border-[color:#ae1b1d] ${transparent ? 'text-red-600' : 'bg-red-600'}`}
        onClick={clickAction}>
        <img loading="lazy" src={imgSrc} alt={altText} className="w-6 aspect-square"/>
        <div className="grow my-auto">{actionText}</div>
    </button>
);

export const Dashboard = () => {
    const [activeModal, setActiveModal] = useState(null);
    const user = useSelector(getUser)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {layouts, loading, widgets} = useSelector(state => state.dashboard);
    const [selectedLayout, setSelectedLayout] = useState(null);


    useEffect(() => {
        dispatch(fetchLayoutsData())
        dispatch(fetchWidgetsData())
    }, []);

    const handleLayoutSelect = (layout) => {
        setSelectedLayout(layout)
        setLayoutsDropdownOpen(false)
        console.log('here')
        dispatch(fetchWidgetsData({layoutId: layout.id}))
    }

    // useEffect(() => {
    //     if (user?.role === 'user') {
    //         navigate('/data-explorer', {
    //             replace: true
    //         })
    //     }
    // }, [user])

    // const [widgets, setWidgets] = useState([])
    console.log(widgets);

    // useEffect(() => {
    //     api.widgets.getAllWidgets().then(response => {
    //         if (!response.success) {
    //             return
    //         }
    //         setWidgets(response.data)
    //     })
    // }, [])
    const CurrentModal = Modals[activeModal];

    const handleClose = () => {
        setActiveModal(null);
    }

    const openModal = (modal) => {
        setActiveModal(modal);
    }

    const actionButtons = [
        {
            imgSrc: AddWidgetIcon,
            altText: "Add Widget Icon",
            actionText: "Add Widget",
            clickAction: () => setActiveModal("addWidget")
        },
        {
            imgSrc: EditLayoutIcon,
            altText: "Edit Layout Icon",
            actionText: "Edit Layout",
            clickAction: () => setActiveModal("addWidget")
        },
        {
            imgSrc: NewConfigIcon,
            altText: "New Config Icon",
            actionText: "New Config",
            clickAction: () => setActiveModal("newConfig")
        },
        {
            imgSrc: SaveIcon,
            altText: "Save Icon",
            actionText: "Save",
            transparent: true,
            borderColorClass: "text-red-600"
        },
        {
            imgSrc: LoadIcon,
            altText: "Load Icon",
            actionText: "Load",
            transparent: true,
            borderColorClass: "text-red-600",
            clickAction: () => setActiveModal("loadConfig")
        },
        {
            imgSrc: ManageConfigsIcon,
            altText: "Manage Configs Icon",
            actionText: "Manage Configs",
            transparent: true,
            borderColorClass: "text-red-600",
            path: "manage-configurations"
        },
    ];


    const [layoutsDropdownOpen, setLayoutsDropdownOpen] = useState(false);

    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => setLayoutsDropdownOpen(false));

    const dropdownRef2 = useRef();
    useClickOutside(dropdownRef2, () => setVesselsDropdownOpen(false));
    const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);
    const [allVessels, setAllVessel] = useState([]);
    const [vessel, setVessel] = useState('');
    const getAllFleets = async () => {
        let res = await api.fleet.getAllVessel();
        setAllVessel(res.data);
    }
    useEffect(() => {
        getAllFleets();
    }, []);
    const handleVesselSelect = (vessel) => {
        setVessel(vessel);
        setVesselsDropdownOpen(false);
    }
    const [startDate, setStartDate] = useState(null);
    const [historyData, setHistoryData] = useState([])
    const [endDate, setEndDate] = useState(null);
    const calendarData = useSelector(getCalendarData)
    const [initialData, setInitialData] = useState([])
    const [initialVessel, setInitialVessel] = useState(null)
    const handleSelectFilter = async () => {
        if (vessel === initialVessel) {
            setHistoryData(initialData.filter(item => item.createdAt >= startDate))
            return
        }
        setInitialVessel(vessel);
        const resp = await api.fleet.getOneHistoryVessel(vessel)
        if (!resp.success) return
        setInitialData(resp.data);
        const result = resp.data.filter(item => item.createdAt >= startDate)
        setHistoryData(result)
    };


    useEffect(() => {
        dispatch(fetchFilterCalendar({vesselId: vessel}))
    }, [vessel])


    const socket = useSelector(getSocket)
    useEffect(() => {
        if (socket) {

            socket.on('vessel-new-waypoint', (data) => {
                if (data?.vessel?.id !== Number(vessel)) {
                    return
                }
                const actual = Array.isArray(data) ? data[0] : data?.waypoint
                // console.log(actual)
                // const result = { ...actual, ...actual.payload }
                // delete result.payload
                // if (result.msg_format === 2) {
                //     result.longditude = result.longitude
                // }
                setHistoryData(prev => ([actual, ...prev]))
                setInitialData(prev => ([actual, ...prev]));
                // setVesselData(prev => ([...prev, actual]))

            });
        }
    }, [socket])
    return (
        <>
            <header
                className="flex gap-5 justify-between pb-2 w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div
                        className="flex flex-col flex-1 justify-center text-neutral-950 pr-2 border-r-[color:#dedede] dark:text-white">
                        <div className="text-base font-semibold tracking-wider">Dashboard Name</div>
                        <div className="text-3xl font-bold tracking-wider whitespace-nowrap"> Mission Summary</div>
                    </div>
                </div>
                <div
                    className="flex gap-2 py-3.5 text-xs font-semibold tracking-wide text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                    {/*{actionButtons.map((button, index) => (*/}
                    {/*    <ActionButton key={index} {...button} />*/}
                    {/*))}*/}

                    <div className="relative z-[100000]" ref={dropdownRef}>
                        <div
                            onClick={() => setLayoutsDropdownOpen(!layoutsDropdownOpen)}
                        >
                            <input
                                value={layouts?.find(item => item?.id === selectedLayout?.id)?.name || ''}
                                type="text"
                                placeholder='Layouts'
                                className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                            />
                        </div>
                        {layoutsDropdownOpen && (
                            <ul className="absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] text-black z-[100000] max-h-[180px] overflow-y-auto">
                                {layouts.length ? layouts.map((one, index) => (
                                    <li key={index}
                                        className=" z-[100000] flex items-center gap-3 hover:bg-slate-50 p-2">
                                        <div onClick={() => handleLayoutSelect(one)}
                                             className="flex-auto truncate cursor-pointer">
                                            {one.name}
                                        </div>
                                    </li>
                                )) : (
                                    <li className='flex items-center gap-3 text-center p-2'>
                                        There is no layouts yet
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>

                </div>
            </header>
            <div className="mt-6">
                <form className={`flex items-center flex-wrap gap-2`}>
                    <div className='flex flex-col col-span-2'>
                        <div className="relative" ref={dropdownRef2}>
                            <div
                                onClick={() => setVesselsDropdownOpen(!vesselsDropdownOpen)}
                            >
                                <input
                                    value={allVessels.find(item => item.id === vessel)?.name || ''}
                                    onChange={(e) => setVessel(e.target.value)}
                                    type="text"
                                    required
                                    placeholder='Vessel name'
                                    className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                                />
                            </div>
                            {vesselsDropdownOpen && (
                                <ul className="absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] z-[1000] max-h-[180px] overflow-y-auto">
                                    {allVessels.length ? allVessels.map((vessel, index) => (
                                        <li key={index} className="flex items-center gap-3 hover:bg-slate-50 p-2">
                                            <div onClick={() => handleVesselSelect(vessel.id)}
                                                 className="flex-auto truncate cursor-pointer">
                                                {vessel.name}
                                            </div>
                                        </li>
                                    )) : (
                                        <li className='flex items-center gap-3 text-center p-2'>
                                            There is no vessels yet
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <DatePicker
                            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                            toggleCalendarOnIconClick
                            showTimeSelect
                            selected={startDate ? new Date(startDate) : null}
                            placeholderText="Start Date"
                            onChange={(date) => setStartDate(date.toISOString())}
                            required
                            dateFormat="dd/MM/yy, HH:mm"
                            highlightDates={calendarData?.map(item => new Date(item.date))}
                        />
                    </div>
                    <div className='flex flex-col'>
                        {/* <DatePicker
                        className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                        // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                        toggleCalendarOnIconClick
                        showTimeSelect
                        selected={endDate ? new Date(endDate) : null}
                        placeholderText="End Date"
                        onChange={(date) => setEndDate(date.toISOString())}
                        required
                        dateFormat="dd/MM/yy, HH:mm"
                        highlightDates={calendarData?.map(item => new Date(item.date))}
                    /> */}
                    </div>
                    <button
                        disabled={!(vessel && startDate)}
                        onClick={handleSelectFilter}
                        type="button"
                        className={`${!(vessel && startDate) ? "opacity-30" : ""} flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]`}
                    >
                        Explore Data
                    </button>


                </form>
            </div>

            {historyData.length && widgets.length ?
                <div className="widgets-wrapper flex flex-wrap gap-5 pt-4">
                    {widgets.map(widget => <Widget key={widget.id} data={widget} historyData={historyData}/>)}
                </div> :
                <p className="text-center mt-2">There is no data for selected parametrs</p>}

            {

                CurrentModal && <CurrentModal close={handleClose} onConfirm={handleClose}/>
            }
            {/* <AddWidgetModal/> */}
        </>
    )
}
