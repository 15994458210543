import '@elfalem/leaflet-curve'
import 'leaflet-rotatedmarker'
import "leaflet-routing-machine"
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useBeforeUnload, useBlocker, useParams } from "react-router-dom"
import indicatorIcon from "../../assets/img/icons/indicator.svg"
import { MapSideMenu } from "../../components/MapSideMenu"
import { MapSideTable } from "../../components/MapSideTable"
import MissionPlanerControls from "../../components/MissionPlanerControls"
import { statusButtons } from "../../components/parts/StatusButtons"
import ConfirmationModal from "../../Modals/ConfirmationModal"
import MissionModal from "../../Modals/MissionModal"
import SateliteMessagesModal from "../../Modals/SateliteMessagesModal"
import { getCurrentMission, setMissonStatus } from "../../store/slices/missionSlice"
import { getSocket } from "../../store/slices/shipsSocket"
import { fetchMissionWayPoints, fetchRewriteAllWayPoints } from "../../store/thunks/fleetThunks"


import chroma from "chroma-js"
import { toast } from "react-toastify"
import api from '../../api'
import TimelineSimulationNew from "../../components/TimelineSimulationNew"
import ConfirmPushMissionModal from '../../Modals/ConfirmPushMissionModal'
import { convertLatLngToApiFormat, isCurrentPoint, isCurrentPointWithTolerance } from "./utils"
import { Waypoint } from "./Waypoint"
import PageLoader from '../../components/PageLoader'
import { createRoot } from 'react-dom/client'
import { PinWithNumber } from '../../components/parts/PinWithNumber'
import { InfoCoordinateModal } from "../../Modals/InfoCoordinateModal";
import { setLastCoordsThunk } from "../../store/thunks/applicationThunks";
import { getUser } from "../../store/slices/applicationSlice";
import { Vessel } from './Vessel'
import TimelineSimulation from '../../components/TimelineSimulation'

const L = window.L;
window.markers = []
window.wayPoints = []


const MAX_OVERLAY_ZOOM = 11;
const MIN_ZOOM = 4;
const MAX_ZOOM = 17;
const DEFAULT_ZOOM = 14;

const PARTICLES_ANIMATION = "on" // "intensive" | "off" | "on"

const options = {
    // Required: API key
    key: process.env.REACT_APP_WINDY_KEY, // REPLACE WITH YOUR KEY !!!

    // Put additional console output
    verbose: false,

    // Optional: Initial state of the map
    // lat: 50.42111324828017,
    // lon: -0.8530883956700565,
    lat: 36.772615760597375,
    lon: -121.92705644472346,
    zoom: 4,
    overlay: 'wind',
    hideOverlay: true,
    graticule: false,
    latlon: false,
    englishLabels: false,
    favOverlays: [],
    level: 'surface',
    particlesAnim: PARTICLES_ANIMATION,
    // isolines: [
    //     // "off",
    //     "pressure",
    //     // "gh",
    //     // "temp"
    // ],
    isolines: 'off',
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM
};


const convertPointsToMapFormat = (points = []) => {
    if (!points?.length) return []
    return points.map(point => {
        point.lng = point.lng || point.lon
        delete point.lon
        return point;
    })
}
const convertPointsToAPIFormat = (points = []) => {
    if (!points?.length) return []
    return points.map(point => {
        return {
            lat: point.lat,
            lon: point.lng,
            missionId: point.missionId,
            id: point.id,
            index: point.index,
            type: point.type,
            title: point.title,
            targetRadius: point.targetRadius,
            isReached: point.isReached,
            repeat_trajectory: point.repeat_trajectory,
            stationDays: point.stationDays,
            stationHours: point.stationHours,
            userId: point.userId,
        };
    })
}
const MissionPlanner = () => {
    const { missionId } = useParams()
    const dispatch = useDispatch()
    const [windy, setWindy] = useState(null);
    const [openedModal, setOpenedModal] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmPushModalOpen, setConfirmPushModalOpen] = useState(false)
    const [infoCoordinateModalOpen, setInfoCoordinateModalOpen] = useState(false)

    const [sateliteMessagesModalOpen, setSateliteMessagesModalOpen] = useState(false);
    const [acceptExit, setAcceptExit] = useState(false);

    const socket = useSelector(getSocket)

    const currentMission = useSelector(getCurrentMission);
    const [activeIndex, setActiveIndex] = useState(0)
    const [historicaMissionIdsForShow, setHistoricaMissionIdsForShow] = useState([])
    const [shownHistoricalWaypoints, setShownHistoricalWaypoints] = useState([])
    const [waypointsFromServer, setWaypointsFromServer] = useState([]);
    const [waypointsFromClient, setWaypointsFromClient] = useState([]);
    const [waypointsToAdd, setWaypointsToAdd] = useState([]);

    const [addWayPointsMode, setAddWayPointsMode] = useState(false);
    const [addStationPointsMode, setAddStationPointsMode] = useState(false);

    // sliderPercentage вырезан с нового компонента TimelineSimulationNew, регулировка windi будет на других ключах
    const [sliderPercentage, setSliderPercentage] = useState(0);
    const [sliderDatePercentage, setSliderDatePercentage] = useState(100);
    const [sliderDateSting, setSliderDateSting] = useState(null)
    const [importantDataCash, setImportantDataCash] = useState({
        vessel: null
    })
    const [vesselMarker, setVesselMarker] = useState(null);
    const [vesselData, setVesselData] = useState([]);

    const [hasLoop, setHasLoop] = useState(false);

    const [tableData, setTableData] = useState([])
    const [lineMarker, setLineMarker] = useState(null)

    // установить слайдер на половину при ините, сразу отфильтрует данные
    // useEffect(() => {
    //     setSliderDatePercentage(50)
    // }, [])
    const [isLoading, setIsLoading] = useState(false);
    const [isWaypointsUpdating, setIsWaypointsUpdating] = useState(false)
    const [isWaypointsLoading, setIsWaypointsLoading] = useState(false)
    const [isMapLoading, setIsMapLoading] = useState(false)
    const [lastVesselData, setLastVesselData] = useState(null)
    const isDraft = currentMission?.status === "draft"
    const [vesselHistory, setVesselHistory] = useState(null)
    const [filteredHistory, setFilteredHistory] = useState([])

    useEffect(() => {
        if (!vesselHistory) return
        const result = vesselHistory?.filter(item => new Date(item.createdAt).getTime() >= new Date(currentMission?.start).getTime() && new Date(item.createdAt).getTime() <= new Date(sliderDateSting).getTime());
        result.sort((a, b) => b.timestamp - a.timestamp)
        setFilteredHistory(result)
    }, [vesselHistory, currentMission?.start, sliderDateSting])

    const mapPositionRef = useRef({ lat: 0, lng: 0 });
    const user = useSelector(getUser)

    const mapOptions = useMemo(() => ([
        {
            label: 'Wind',
            default: true,
            onSelect: () => {
                windy.store.set('overlay', 'wind')
                windy.store.set('particlesAnim', PARTICLES_ANIMATION);
                // windy.map.setMaxZoom(MAX_OVERLAY_ZOOM);
            }
        },
        {
            label: 'Default',
            default: false,
            onSelect: () => {
                windy.store.set('overlay', 'temp')
                windy.store.set('particlesAnim', 'off')

            }
        },
        {
            label: 'Pressure',
            default: false,
            onSelect: () => {
                windy.store.set('overlay', 'pressure')
                windy.store.set('particlesAnim', PARTICLES_ANIMATION)
                // windy.map.setMaxZoom(MAX_OVERLAY_ZOOM);
            }
        },
        // {
        //     label: 'Waves',
        //     default: false,
        //     onSelect: () => {
        //         windy.store.set('overlay', "waves");
        //         windy.store.set('particlesAnim', 'on');
        //     }
        // }
    ]), [windy]);

    const createWayPoint = (coord) => {
        if (window.isWaypointPopupOpen) return;
        if (!window.addWayPointsMode && !window.addStationPointsMode) return;

        if (window.hasLoop || hasLoop) {
            toast.warn("To add new waypoint remove the loop first")
            return
        }
        new Waypoint(
            { ...coord.latlng, missionId },
            {
                setWaypoints: setWaypointsFromClient
            })
    }

    const addFromCoordinatesArray = (array) => {
        if (!array?.length) return;
        setAddWayPointsMode(true);
        const start = waypointsFromClient.length
        const waypointsToAdd = array.map((item, index) =>
            new Waypoint({ ...item, missionId, index: start + index }, { setWaypoints: setWaypointsFromClient })
        )
        if (!windy || !windy.map || !waypointsToAdd?.length) return;
        const map = windy.map;
        if (waypointsToAdd.length === 1) {
            const singlePoint = waypointsToAdd[0];
            map.setView([singlePoint.lat, singlePoint.lng], DEFAULT_ZOOM);
        } else {
            const bounds = L.latLngBounds(waypointsToAdd.map(point => [point.lat, point.lng]));
            map.fitBounds(bounds, { padding: [200, 200], maxZoom: DEFAULT_ZOOM });
        }
    }

    const getAndShowOtherMissionWaypoints = async (missionId) => {
        setIsLoading(true)
        const res = await dispatch(fetchMissionWayPoints(missionId));
        if (res?.payload) {
            const waypoints = res.payload
            if (!waypoints && !waypoints.length) return;
            setTimeout(() => {
                // setShownHistoricalWaypoints(sortWaypoints(waypoints)
                setShownHistoricalWaypoints(waypoints
                    .map(point => (
                        new Waypoint(
                            { ...point, missionId, isHistorical: true, index: waypoints.length },
                            {
                                setWaypoints: setWaypointsFromClient
                            })
                    )))
                setIsLoading(false)
            }, 100);
        }
    }

    const addVesselHistory = (map, icon) => {

        if (window.lineMarker) {
            map.removeLayer(window.lineMarker)
        }

        if (window.lineTrack) {
            map.removeLayer(window.lineTrack)
            window.lineTrack.remove()
        }

        function groupByWaypoint(data) {
            const groupedData = {};

            data.forEach(item => {
                const key = item.msg_format === 1 ? `${item.target_waypoint_lat}-${item.target_waypoint_long}` : `${item.target_waypoint_latitude}-${item.target_waypoint_longitude}`;
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(item.msg_format === 1 ? [item.latitude, item.longditude,] : [item.latitude, item.longitude,]);
            });

            return Object.values(groupedData);
        }


        const sliderDate = new Date(sliderDateSting).getTime();

        const filteredVesselData = vesselData.filter(item => {
            const itemTimestamp = item.timestamp * 1000;
            return itemTimestamp <= sliderDate;
        }).sort((a, b) => b.timestamp - a.timestamp)

        const groupedData = groupByWaypoint(filteredVesselData);

        const colors = chroma.scale(['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'])
            .mode('lch').colors(groupedData.length)

        // линии за кораблем
        let multiSegment = L.featureGroup(groupedData.map((item, index, array) => {
            const coordinates = [...item];
            const next = array[index + 1]
            if (next) {
                coordinates.push(next[0])
            }
            return L.polyline(coordinates, { color: colors[index] });
        })).addTo(map);

        window.lineTrack = multiSegment

        const index = 0
        // const index = vesselData.length - 1

        if (filteredHistory && filteredHistory[index]) {
            // точка самого корабля
            // let vesselMarker = L.marker([filteredVesselData[index].latitude, filteredVesselData[index].longditude], {
            //     icon,
            //     rotationAngle: filteredVesselData[index].target_heading,
            //
            // }).addTo(map);
            // setVesselMarker(vesselMarker)


            if (window.vesselMarker) {
                window.vesselMarker.destroy()
                map.removeLayer(window.vesselMarker);
            }

            let vesselMarker = new Vessel({
                lat: filteredHistory[index]?.latitude,
                lng: filteredHistory[index]?.longitude,
                rotationAngle: filteredHistory[index]?.target_heading
            })

            window.vesselMarker = vesselMarker

            setVesselMarker(vesselMarker)

            // setLineMarker(vesselHistory)
            // window.lineMarker = vesselHistory
        }


        // let trackCoordinates = [
        //     [vesselData[0].latitude, vesselData[0].longditude],
        //     [vesselData[vesselData.length -1].latitude, vesselData[vesselData.length -1].longditude]
        // ];
        let trackCoordinates = [...vesselData.map(item => ([item.latitude, item.longditude]))]
        // const uniquePoints = Array.from(new Set(vesselData.map(item => {
        //     if(item.target_waypoint_long) return item.target_waypoint_long + ',' + item.target_waypoint_lat
        //     return item.target_waypoint_longitude + ',' + item.target_waypoint_latitude
        // }))).map(coordString => coordString.split(',').map(Number));
        // uniquePoints.forEach((item, index) => {
        //     // if (index === vesselData.length - 1) return
        //     // if(isNearPoint({ lat: item.latitude, lng: item.longditude })) return

        //     // синие точки без дизайна, просто метки
        //     addVesselWaypoint(item[1], item[0])
        // })
        // let vesselTrack = L.polyline(trackCoordinates, {
        //     color: 'black',
        //     dashArray: '5, 10'
        // }).addTo(map);
        // setLineTrack(vesselTrack)


        // if (groupedData.length) {
        //     map.fitBounds(multiSegment.getBounds());
        // }


        window.wayPoints.forEach(item => {
            // const isCurrent = isCurrentPoint(item._latlng, {
            //     lat: filteredVesselData?.[0]?.latitude,
            //     lng: filteredVesselData?.[0]?.longitude
            // })
            const isCurrent = isCurrentPointWithTolerance(item._latlng, {
                lat: filteredVesselData?.[0]?.latitude,
                lng: filteredVesselData?.[0]?.longitude
            }, filteredVesselData?.[0]?.target_waypoint_radius || 25)


            // if (!isCurrent || currentMission.status !== 'in_progress') return;
            // if (!isCurrent) return;
            const createPathIcon = async () => {
                const html = document.createElement('div');
                const root = createRoot(html);

                return new Promise(resolve => {
                    root.render(
                        <PinWithNumber
                            number={item.index + 1}
                            isHistorical={item.isHistorical || item.isReached}
                            type={item.type}
                            isCurrent={isCurrent}
                            onRendered={() => resolve(L.divIcon({
                                iconSize: [48, 48],
                                iconAnchor: [24, 48],
                                html: html.innerHTML
                            }))}
                        />
                    );
                });
            }
            createPathIcon().then(icon => item._marker.setIcon(icon))
        })
    }

    const initCustomWindy = (windyAPI) => {
        const { map, colors } = windyAPI;
        colors.temp.changeColor([[203, [199, 223, 238]], [320, [199, 223, 238]]]);

        map.on('click', createWayPoint);


        map.options.minZoom = MIN_ZOOM;
        map.options.maxZoom = MAX_ZOOM;

        const topLayer = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "Mapbox",
            detectRetina: false,
            maxNativeZoom: 19,
            // maxZoom: MAX_ZOOM,
            // minZoom: 12,
            noWrap: false,
            opacity: 0,
            subdomains: "abc",
            tms: false,
        }).addTo(map);
        window.topLayer = topLayer

        // windyAPI.store.on("overlay", (overlay) => console.log(overlay, "overlay"))

        // windyAPI.store.on("timestamp", (timestamp) => console.log(timestamp, "timestamp"))

        const updateMapPosition = () => {
            const center = map.getCenter();
            mapPositionRef.current = { lat: center.lat, lng: center.lng };
        };

        map.on('moveend', updateMapPosition);

        map.on('zoomend', function () {
            if (map.getZoom() >= 12) {
                topLayer.setOpacity('1');
            } else {
                topLayer.setOpacity('0');
            }
        });


        window.customWindy = windyAPI;
        setWindy(windyAPI);
        setIsMapLoading(false)
        setTimeout(() => {
            windyAPI.store.set('overlay', 'wind')
            windyAPI.store.set('particlesAnim', PARTICLES_ANIMATION);
            if (user?.homeAddress?.latitude && user?.homeAddress?.longitude) {
                map?.setView([user.homeAddress.latitude, user.homeAddress.longitude], 5);
            } else if (user?.lastAddress?.latitude && user?.lastAddress?.longitude) {
                map?.setView([user.lastAddress.latitude, user.lastAddress.longitude], 5);
            }
        }, 100)
    };

    const initWindyForce = () => {
        setIsMapLoading(true)
        window.windyInit(options, initCustomWindy);
    };

    useLayoutEffect(() => {
        if (windy) return;
        initWindyForce();
    }, [windy]);

    useEffect(() => {
        return () => {
            dispatch(setLastCoordsThunk({
                latitude: mapPositionRef.current?.lat,
                longitude: mapPositionRef.current?.lng
            }))
        }
    }, []);


    useEffect(() => {
        if (historicaMissionIdsForShow?.length) {
            historicaMissionIdsForShow.forEach(missionId => {
                setTimeout(() => {
                    getAndShowOtherMissionWaypoints(missionId);
                }, 100);
            });
        }
    }, [historicaMissionIdsForShow]);

    const drawCurves = (map, waypoints, isHistorical) => {
        if (!waypoints || waypoints.length <= 1) return;

        const mainPath = waypoints.flatMap((el, idx) => [
            idx === 0 ? 'M' : 'L',
            [el.lat, el.lng]
        ]);

        L.curve(mainPath, {
            color: isHistorical ? "#343C44" : "#3477B5",
            fill: false,
            dashArray: "5 5"
        })?.addTo(map);

        waypoints.forEach(el => {
            const loopToIndex = waypoints.findIndex(wp => wp.index + 1 === parseInt(el.repeat_trajectory));
            if (loopToIndex >= 0) {
                const loopToWaypoint = waypoints[loopToIndex];
                const loopLine = ['M', [el.lat, el.lng], 'L', [loopToWaypoint.lat, loopToWaypoint.lng]];

                L.curve(loopLine, {
                    color: isHistorical ? "#ff000080" : "red",
                    fill: false,
                    dashArray: "5 10"
                })?.addTo(map);
            }
        });
    };

    const removeAndRedrawCurves = useCallback((map, waypoints) => {
        map.eachLayer(layer => {
            if (layer instanceof L.Curve) {
                map.removeLayer(layer);
            }
        });
        drawCurves(map, waypointsFromClient);
        if (waypoints?.length) {
            drawCurves(map, waypoints, true);
        }
    }, [waypointsFromClient])

    useEffect(() => {
        if (!windy?.map) return;
        const map = windy.map;
        removeAndRedrawCurves(map, shownHistoricalWaypoints);
    }, [shownHistoricalWaypoints, waypointsFromServer, waypointsFromClient, windy, removeAndRedrawCurves]);

    useEffect(() => {
        if (!currentMission) return;
        const isComplete = currentMission.status === 'complete'
        const draft = JSON.parse(window.localStorage.getItem("draft"));
        const existingIds = new Set(waypointsFromClient.map(p => p.id));
        if (!windy?.map) return;

        if (draft && +draft.id !== +missionId) {
            handleCancelChanges()
        }

        const waypointsToAdd = draft && +draft.id === +missionId && Array.isArray(draft.waypoints) && !isComplete
            ? draft.waypoints.filter(point => !existingIds.has(point.id))
            : waypointsFromServer.filter(point => !existingIds.has(point.id));

        waypointsToAdd.forEach(point => new Waypoint({ ...point, missionId }, { setWaypoints: setWaypointsFromClient }));
        setWaypointsToAdd(convertPointsToMapFormat(waypointsToAdd));
        if (currentMission.activeIndex) {
            setActiveIndex(currentMission.activeIndex)
        }
    }, [waypointsFromServer, currentMission, windy]);


    useEffect(() => {
        if (!windy || !windy.map || !waypointsToAdd?.length) return;
        const map = windy.map;
        if (waypointsToAdd.length === 1) {
            const singlePoint = waypointsToAdd[0];
            map.setView([singlePoint.lat, singlePoint.lng], DEFAULT_ZOOM);
        } else {
            const bounds = L.latLngBounds(waypointsToAdd.map(point => [point.lat, point.lng]));
            map.fitBounds(bounds, { padding: [200, 200], maxZoom: DEFAULT_ZOOM });
        }
    }, [waypointsToAdd, windy, vesselData])

    useEffect(() => {
        if (!waypointsFromClient?.length) return
        const isHasLoop = Boolean(waypointsFromClient[waypointsFromClient.length - 1]?.repeat_trajectory)
        setHasLoop(isHasLoop)
        window.hasLoop = isHasLoop
    }, [waypointsFromClient])

    const destroy = () => {
        // window.wayPoints.forEach(point => point.destroy && point.destroy());
        window.wayPoints.forEach(point => point.destroy && point.remove());
        setWaypointsFromClient([]);
        window.wayPoints = [];
        window.markers = [];
        window.localStorage.removeItem("draft")
    };

    // useEffect(() => {
    //     return () => {
    //         console.warn("Component unmount");
    //         destroy();
    //     };
    // }, []);


    useEffect(() => {
        window.addWayPointsMode = addWayPointsMode
        window.addStationPointsMode = addStationPointsMode
    }, [addWayPointsMode, addStationPointsMode]);


    const handleBlock = useCallback(() => {
        if (!acceptExit) {
            setConfirmModalOpen(true)
            return true
        } else {
            return false
        }
    }, [acceptExit]);

    let blocker = useBlocker(handleBlock);

    const handleConfirm = async () => {
        setIsWaypointsUpdating(true)

        const waypoints = convertPointsToAPIFormat(waypointsFromClient)
        const res = await dispatch(fetchRewriteAllWayPoints({
            missionId,
            body: {
                waypoints
            }
        })).unwrap()
        if (res?.waypoints) {
            destroy()
            setWaypointsFromServer(convertPointsToMapFormat(waypoints))
        }
        setIsWaypointsUpdating(false)
    }

    const handleConirmPushMission = async (data) => {
        setConfirmPushModalOpen(false)
        const activeIndex = parseInt(data.activeIndex);
        if (!waypointsFromClient.length) {
            toast.error("Mission must include at least one waypoint")
            return;
        }

        if (isNaN(activeIndex) || activeIndex < 0 || activeIndex >= waypointsFromClient.length) {
            toast.error(`Active index must in range of current mission waypoint list (0 - ${waypointsFromClient.length - 1})`)
            return;
        }

        const waypoints = convertPointsToAPIFormat(waypointsFromClient);
        const result = await dispatch(fetchRewriteAllWayPoints({
            missionId,
            body: {
                waypoints
            }
        })).unwrap()
        if (!result) {
            toast.error(result.message || "Something went wrong")
            return
        }

        const res = await api.fleet.updateMission({
            status: "in_progress",
            activeIndex,
        }, missionId);

        if (!res.success) {
            return
        }

        const pushRes = await api.fleet.pushMission(missionId);

        toast(pushRes.message, {
            type: pushRes.success ? 'success' : 'error'
        })

        if (!pushRes.success) {
            return
        }

        if (pushRes.success) {
            setActiveIndex(activeIndex)
            dispatch(setMissonStatus("in_progress"))
            handleCancelChanges()
        }
    }
    const handlePushMission = () => {
        if (currentMission.status === "draft") {
            handleConirmPushMission({ activeIndex: 0 })
        } else {
            setAddWayPointsMode(false)
            setConfirmPushModalOpen(true)
        }
    }
    const handleCancelChanges = async () => {
        setIsWaypointsLoading(true)
        destroy()
        const res = await dispatch(fetchMissionWayPoints(missionId));
        if (res?.payload) {
            setWaypointsFromServer(convertPointsToMapFormat(res.payload))
        }
        setIsWaypointsLoading(false)

    }
    const handleConfirmExit = async () => {
        setAcceptExit(true)
        setConfirmModalOpen(false)
    }
    const handleSetAddWayPointsMode = (enable) => {
        setAddWayPointsMode(enable)
    }
    const handleAddStationPointsMode = (enable) => {
        setAddStationPointsMode(enable)
    }

    const isReachedLayer = (layer) => {
        return waypointsFromClient.some(point => isCurrentPoint(layer._latlng, convertLatLngToApiFormat(point)) && point.isReached)
    }

    useBeforeUnload((event) => {
        if (!acceptExit) event.preventDefault()
    });


    // useEffect(() => {
    //     if (windy && missionWayPoints?.length && waypointsFromServer?.length !== missionWayPoints?.length) {
    //         const convertedWaypoints = convertPointsToMapFormat(missionWayPoints)
    //         setWaypointsFromServer(convertedWaypoints);
    //     }
    // }, [missionWayPoints, windy]);


    useEffect(() => {
        if (windy?.map) {
            const map = windy.map;

            var myIcon = L.icon({
                iconUrl: indicatorIcon,
                iconSize: [36, 49],
                iconAnchor: [18, 25],
                shadowUrl: null

            });
            addVesselHistory(map, myIcon)
        }

    }, [sliderDatePercentage, vesselData]);
    // }, [sliderDatePercentage, vesselData]);


    useEffect(() => {
        if (blocker.state === 'blocked' && !acceptExit) {
        } else if (blocker.state === 'blocked' && acceptExit) {
            blocker.proceed();
        }
    }, [blocker, acceptExit]);

    useEffect(() => {
        return () => {
            setConfirmModalOpen(true)
        }
    }, [])

    useEffect(() => {

        if (currentMission?.end) {
            setAcceptExit(true)
        }
    }, [currentMission]);

    useEffect(() => {
        if (!windy?.map) return
        const map = windy.map
        setTimeout(() => {
            if (addStationPointsMode || addWayPointsMode) {
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker) {
                        if (isReachedLayer(layer)) return;
                        layer.dragging.enable()
                    }
                });
            } else if (!(addStationPointsMode && addWayPointsMode)) {
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker) {
                        layer.dragging.disable()
                    }
                });
            }
            // map.eachLayer(layer => {
            //     if (layer instanceof L.Marker) {
            //         const createPathIcon = async () => {
            //             const html = document.createElement('div');
            //             const root = createRoot(html);

            //             return new Promise(resolve => {
            //                 root.render(
            //                     <PinWithNumber
            //                         number={123}
            //                         isHistorical={false}

            //                         onRendered={() => resolve(L.divIcon({
            //                             iconSize: [48, 48],
            //                             iconAnchor: [24, 48],
            //                             html: html.innerHTML
            //                         }))}
            //                     />
            //                 );
            //             });
            //         }
            //         createPathIcon().then(icon => layer.setIcon(icon))
            //         // console.log('LAYER', layer.setIcon(newIcon))
            //     }
            //     });
        }, 100)
    }, [addStationPointsMode, addWayPointsMode, waypointsFromClient, waypointsFromServer, windy])

    useEffect(() => {
        if (!currentMission?.vesselId) return
        api.fleet.getOneHistoryVessel(currentMission?.vesselId).then(response => {
            setIsLoading(false)
            if (!response.success) {
                return
            }
            setVesselHistory(response.data.map(item => {
                if (item?.payload) {
                    const result = { ...item, ...item.payload }
                    delete result.payload
                    return result
                } else {
                    return item
                }
            }).reverse())
        }).catch(err => console.error(err))
    }, [currentMission?.vesselId])

    useEffect(() => {
        if (socket && currentMission?.vesselId) {
            socket.emit('vessel-histories', {
                "vesselId": currentMission.vesselId
            })

            socket.on('vessel-histories', (data) => {
                const missionData = data.filter(item => item?.dataHistory?.missionId === currentMission.id) || [];

                const formatData = missionData.map(item => {
                    if (item?.payload) {
                        const result = { ...item, ...item.payload }
                        delete result.payload
                        delete result.dataHistory
                        if (result.msg_format === 2) {
                            result.longditude = result.longitude
                        }
                        return result
                    } else {
                        return item
                    }
                })
                setTableData(formatData[0])
                setVesselData(formatData)
                setLastVesselData(data && data[data.length - 1])
            })
            socket.on('vessel-new-waypoint', (data) => {
                if (data?.missionHistory?.missionId !== currentMission.id) {
                    return
                }
                const actual = Array.isArray(data) ? data[0] : data?.waypoint
                const result = { ...actual, ...actual.payload }
                delete result.payload
                if (result.msg_format === 2) {
                    result.longditude = result.longitude
                }
                setTableData(result)
                // setVesselData(prev => ([...prev, actual]))
                const lineMarker = window.lineMarker
                if (lineMarker) {
                    // const newAngle = geoUtilAngle(windy.map, lineMarker._latlng, {
                    //     lat: result?.target_waypoint_lat,
                    //     lng: result?.target_waypoint_long,

                    // });
                    lineMarker.setLatLng([result?.latitude, result?.longditude])
                    // lineMarker.setRotationAngle(newAngle)
                    lineMarker.setRotationAngle(result.target_heading)
                    // addVesselWaypoint(result?.latitude, result?.longditude)
                }
                const lineTrack = window.lineTrack
                if (lineTrack) {

                    const layer = lineTrack.getLayers()?.[0]
                    if (layer) {
                        let coords = layer.getLatLngs()
                        coords = [[result?.latitude, result?.longditude], ...coords]
                        layer.setLatLngs(coords)
                    }
                }
                setVesselData(prev => [result, ...prev])
            });
        }
    }, [socket, currentMission])

    const getMissionWayPoints = async () => {
        setIsWaypointsLoading(true)
        const res = await dispatch(fetchMissionWayPoints(missionId)).unwrap();
        if (res) {
            setWaypointsFromServer(res)
        }
        setIsWaypointsLoading(false)
    }
    useEffect(() => {
        getMissionWayPoints();
    }, [missionId]);

    const addVesselIcon = (map, vesselData) => {
        const updatedDate = new Date(vesselData?.updatedAt);
        const currentDate = new Date();
        const hoursDifference = (currentDate - updatedDate) / (1000 * 60 * 60);

        if (hoursDifference > 24) {
            return;
        }

        const { latitude, longitude, target_heading } = vesselData?.payload;

        if (window.vesselMarker) {
            window.vesselMarker.destroy()
            map.removeLayer(window.vesselMarker);
        }
        window.vesselMarker = new Vessel({ lat: latitude, lng: longitude, rotationAngle: target_heading })

        map.setView([latitude, longitude], DEFAULT_ZOOM);
    };

    useEffect(() => {
        if (!isDraft && lastVesselData && windy?.map && !vesselData?.length) {
            addVesselIcon(windy?.map, lastVesselData)
        }
    }, [lastVesselData, windy]);

    useEffect(() => {
        if (isDraft && windy?.map && waypointsFromClient?.length) {
            const firstPoint = waypointsFromClient[0]
            const secondPoint = waypointsFromClient[1]
            let angle = 0
            if (secondPoint) {
                angle = L.angle(windy?.map, firstPoint._latlng, secondPoint._latlng);
            }
            if (window.vesselMarker) {
                window.vesselMarker.destroy()
            }
            window.vesselMarker =
                new Vessel({ lat: firstPoint.lat, lng: firstPoint.lng, rotationAngle: angle })
        }
    }, [waypointsFromClient, windy])

    useEffect(() => {
        const hasModalOpened = sessionStorage.getItem("infoCoordinateModalOpened");

        if (!hasModalOpened) {
            setInfoCoordinateModalOpen(true);
            sessionStorage.setItem("infoCoordinateModalOpened", "true");
        }
    }, []);

    const isGlobalLoading = isLoading || isWaypointsUpdating || isWaypointsLoading || isMapLoading;

    return (
        <div className="h-screen">
            {/* {isLoading && <div className='cover-map-loading'></div>} */}
            {isGlobalLoading && <PageLoader />}
            <div className="fixed top-[10px] left-[10px] z-10">
                {
                    !confirmPushModalOpen && (
                        <>
                            <MapSideMenu>
                                <a
                                    href="http://planner.it"
                                    target="_blank"
                                    className="group flex items-center w-full mt-4 justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]"
                                    rel="noreferrer"
                                >
                                    How to Use
                                </a>
                            </MapSideMenu>
                            <button
                                onClick={() => setSateliteMessagesModalOpen(true)}
                                className="group flex items-center w-full mt-6 justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]">
                                View satellite messages
                            </button>
                            {/*<MapSideTable tableData={tableData} />*/}
                        </>
                    )
                }
            </div>

            <div className="fixed top-[10px] right-[10px] z-10 flex gap-2 pointer-events-none">
                {
                    currentMission?.status &&
                    <div className="flex items-start h-fit h-fit-bg">
                        {statusButtons[currentMission.status]}
                    </div>
                }
                {
                    !confirmPushModalOpen && (
                        <MissionPlanerControls
                            missionId={missionId}
                            addWayPointsMode={addWayPointsMode}
                            handleSetAddWayPointsMode={(enable) => handleSetAddWayPointsMode(enable)}
                            handleAddStationPointsMode={(enable) => handleAddStationPointsMode(enable)}
                            currentMissionStatus={currentMission?.status}
                            setAddWayPointsMode={(val) => setAddWayPointsMode(val)}
                            addStationPointsMode={addStationPointsMode}
                            setAddStationPointsMode={setAddStationPointsMode}
                            mapOptions={mapOptions}
                            setOpenedModal={setOpenedModal}
                            addFromCoordinatesArray={addFromCoordinatesArray}
                            historicaMissionIdsForShow={historicaMissionIdsForShow}
                            setHistoricaMissionIdsForShow={setHistoricaMissionIdsForShow}
                            wayPoints={waypointsFromClient.map(point => point.getData())}
                            hasLoop={hasLoop}
                            onCancel={handleCancelChanges}
                            onPush={handlePushMission}
                        />
                    )
                }
            </div>

            <div className="fixed bottom-[16px] right-[20px] z-[100]">
                {!isDraft && currentMission && (<TimelineSimulationNew
                    currentMission={currentMission}
                    windy={windy}
                    sliderPercentage={sliderDatePercentage}
                    setSliderPercentage={setSliderDatePercentage}
                    callBackSliderDate={setSliderDateSting}
                />)}
            </div>
            <div className="fixed bottom-[16px] left-[20px] z-[100] w-[calc(100%-40px)]">
                {
                    currentMission && isDraft && (
                        <TimelineSimulation
                            currentMission={currentMission}
                            wayPoints={waypointsFromClient}
                            windy={windy}
                            vessel={window.vesselMarker}
                            onPlay={() => {
                                setAddWayPointsMode(false)
                                setAddStationPointsMode(false)
                            }}
                        />
                    )
                }

            </div>
            <div id="windy" className={`size-full`}
                style={confirmPushModalOpen ? { pointerEvents: "none" } : {}}
            ></div>
            {openedModal !== null && <MissionModal
                missionId={missionId}
                close={() => setOpenedModal(null)}
                type={openedModal}
                onConfirm={handleConfirm}
            />}
            {
                confirmModalOpen && currentMission?.status !== "complete" &&
                <ConfirmationModal
                    title="Exit Without Saving?"
                    message="You have unsaved changes. Are you sure you want to exit without saving?"
                    close={() => setConfirmModalOpen(false)}
                    onConfirm={handleConfirmExit} />
            }
            {
                sateliteMessagesModalOpen &&
                <SateliteMessagesModal
                    data={vesselData}
                    close={() => setSateliteMessagesModalOpen(false)}
                />
            }
            {
                confirmPushModalOpen &&
                <ConfirmPushMissionModal
                    activeIndex={activeIndex}
                    maxIndex={waypointsFromClient.length - 1}
                    close={() => setConfirmPushModalOpen(false)}
                    onConfirm={(data) => handleConirmPushMission(data)}
                />
            }
            {
                infoCoordinateModalOpen &&
                <InfoCoordinateModal
                    onClose={() => setInfoCoordinateModalOpen(false)}
                />
            }
        </div>
    )
}

export default MissionPlanner;
