import { ReactComponent as WindDial } from '../../assets/img/wind- dial.svg'
import ExplorerMap from "../../components/ExplorerMap"
import { BarChart } from "../../components/widgets/BarChart"
import ErrorsWidget from "../../components/widgets/ErrorsWidget"
import { LineChart } from "../../components/widgets/LineChart"
import NumericalWidget from "../../components/widgets/NumericalWidget"
import PieChartWind from "../../components/widgets/PieChartWindSpeed"
import PieChartWithNeedle from "../../components/widgets/PieChartWithNeedle"

const Widget = ({ data, historyData }) => {
    if (!historyData.length) {
        return 'No data mathes your filters'
    }
    const historyPoints = historyData.map(item => {
        const result = { ...item, ...item.payload }
        delete result.payload
        return result
    })
    const widgetsMap = {
        map_view: <ExplorerMap historyPoints={historyPoints} closestDateObject={historyPoints[0]} />,
        trafic_light: <ErrorsWidget data={historyPoints[0]} config={data.config} />,
        battery_life: <NumericalWidget data={historyPoints[0]} config={data} />,
        distance: <NumericalWidget data={historyPoints[0]} config={data} />,
        time: <NumericalWidget data={historyPoints[0]} config={data} />,
        // line_graph: <LineChart config={data} data={historyPoints} />,
        line_graph: <LineChart config={data} data={historyPoints} />,
        pie_chart: <PieChartWind title={data.name} value={historyPoints[0].avg_wind_speed + 'kts'} needleValue={historyPoints[0].avg_wind_speed * 100 / 30} />
    }
    const currentWidget = widgetsMap[data.type] || null
    const sizes = {
        sm: '1/6',
        md: '1/3',
        lg: '1/2'
    }

    return (
        data.type === 'battery_status' ? <>
            <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                <NumericalWidget data={historyPoints[0]} config={{
                    name: 'Average Power Consumption',
                    config: {
                        average_power_consumption: 'average_power_consumption'
                    }
                }} />
            </div>
            <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                <BarChart config={{
                    name: 'Total Amp Hours Consumed',
                    config: {
                        total_amp_hour_consumption: data.config.total_amp_hour_consumption
                    }
                }} data={historyPoints[0]} />
            </div>
            <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                <NumericalWidget data={historyPoints[0]} config={{
                    name: 'Battery Voltage',
                    config: {
                        battery_voltage_average: 'battery_voltage_average'
                    }
                }} />
            </div>
        </> :
            data.type === 'circular_progress' ? <>
                <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                    <PieChartWithNeedle title={'GPS Speed'} value={historyPoints[0].average_gps_speed + ' m/s'} needleValue={historyPoints[0].average_gps_speed * 100 / 12} />
                </div>
                <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                    <PieChartWithNeedle title={'Speed On Target'} value={historyPoints[0].average_speed_towards_target_waypoint + ' m/s'} needleValue={historyPoints[0].average_speed_towards_target_waypoint * 100 / 12} />
                </div>

            </> :
                data.type === 'wind' ? <>
                    <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                        <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
                            Wind Speed And Direction
                        </div>
                        <div className="flex items-center flex-col mt-2">
                            <div
                                className="flex items-center gap-[8px] text-[16px] text-[#e42320] font-semibold mb-2">
                                <span>{historyPoints[0]?.avg_wind_direction?.toFixed(2)}°</span>
                                <span>{historyPoints[0]?.navigator_wind_speed_average?.toFixed(2)} м/с</span>
                            </div>
                            <div style={{ transform: `rotate(${historyPoints[0]?.avg_wind_direction}deg)` }}
                                className="flex flex-col items-center">
                                <WindDial style={{
                                    width: 96,
                                    height: 96
                                }} />
                            </div>
                        </div>
                    </div>

                </> :
                    <div className={`widget-card rounded w-${sizes[data.size]}-gap-5 h-[235px] border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white`}>
                        {currentWidget}
                        {/* <LineChart title={data.name} openModal={() => { }} /> */}
                    </div>
    )
}

export default Widget