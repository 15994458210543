import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getAuthorized, getUser, } from '../store/slices/applicationSlice';


const ProtectedRouteLayout = () => {
    const authorized = useSelector(getAuthorized);
    const user = useSelector(getUser)
    const { pathname } = useLocation();
    
    if (authorized !== true) {
        return null
    }
    const screenAccess = user?.screenAccess || {};

    const commonPath = '/account'

    if (pathname.startsWith('/dashboard') && !screenAccess.dashboard) return <Navigate to={commonPath} replace />;
    if (pathname.startsWith('/data-explorer') && !screenAccess.explorer) return <Navigate to={commonPath} replace />;
    if (pathname.startsWith('/map-view') && !screenAccess.map) return <Navigate to={commonPath} replace />;
    if (pathname.startsWith('/mission-planner') && !screenAccess.planner) return <Navigate to={commonPath} replace />;


    return (
        <Outlet />
    )
};

export default ProtectedRouteLayout
